import './MainPage.style.css'
import juliette from '../Assets/juliette_billy.jpeg'
import gabriel from '../Assets/gabriel_ski.jpeg'
import louis from '../Assets/louis_party.png'
import elina from '../Assets/elina.png'

const MainPage = () => {
  return (
    <div className="main">
      <h3>BUILD OK</h3>
      <p>!!! Attention ces personnes sont très dangereuses !!!</p>
      <p>
        {
          ' => si vous les voyez appelez les services de police immédiatement <= '
        }
      </p>
      <img className="photo" src={juliette} alt="juliette"></img>
      <p>Juliette Billy</p>
      <p>Recherchée pour traffic de stupéfiant (covid)</p>
      <img className="photo" src={gabriel} alt="gabriel"></img>
      <p>Gabriel Mahé</p>
      <p>Recherché pour attentat dans les toilettes</p>
      <img className="photo" src={louis} alt="louis"></img>
      <p>Louis Vaillant</p>
      <p>Recherché pour avoir oublié la galette</p>
      <img className="photo" src={elina} alt="elina"></img>
      <p>Elina Dallay</p>
      <p>Recherchée pour intoxication alimentaire aux oeufs avariés</p>
    </div>
  )
}

export default MainPage
