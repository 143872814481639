import './Contact.style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Contact = () => {
  return (
    <div className="contact">
      <h2>Nous contacter</h2>
      <p>Covid party coloc</p>
      <p>
        <FontAwesomeIcon icon="map" color="white" /> 27 rue Rebeval, Paris
      </p>
      <p>
        <FontAwesomeIcon icon="phone" color="white" /> 099 998 229
      </p>
    </div>
  )
}

export default Contact
