import './App.css'
import { Route, Routes } from 'react-router'
import MainPage from './MainPage'
import Contact from './Contact'
import TopBar from './TopBar'

const App = () => {
  return (
    <div className="App">
      <h1>La villa des covidés</h1>
      <TopBar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  )
}

export default App
