import { Link } from 'react-router-dom'
import './TopBar.style.css'

const TopBar = () => {
  return (
    <div className="topBar">
      <Link to={'/'} className="link">
        Accueil
      </Link>
      <Link to={'/contact'} className="link">
        Contact
      </Link>
    </div>
  )
}

export default TopBar
